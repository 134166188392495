body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.topcorner{
  position:absolute;
  top:0;
  right:0;
  padding: 1%;
 }

.rowC {
  padding-top: 6%;
  display: flex;
  flex-direction: row;
}

.center {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  background: red;
  }

.space {
  
  width: 10%;

  }
  .chart {
    height: auto;
    width: 25%;
  }
